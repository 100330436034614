import React from "react";
import { Alert, Card, Col, Jumbotron, Row } from "react-bootstrap";
import { FaCheck } from 'react-icons/fa';
import styled from 'styled-components';
import { PublicLayout } from "../components/layout";
import { Buttons, Seo } from '../components/navigation';
import { ANIMATION_DURATION_SECS, fadeInRefs, slideInFromBottomRefs } from "../utils/Animate";

const GreenTick = styled(FaCheck)`
color: green;
margin-right: 5px;
margin-bottom: 3px;
`

const IndexPage = () => {

    const refFirst = React.useRef(null)
    const refSecond = React.useRef(null)

    React.useEffect(() => {
        slideInFromBottomRefs([refFirst, refSecond], ANIMATION_DURATION_SECS)
        fadeInRefs([refFirst, refSecond], ANIMATION_DURATION_SECS)
    }, [])

    return <PublicLayout>
        <Seo.Plans />

        <Jumbotron className="bg-white mb-0">
            <h2 className="mb-4">Compare Features</h2>
            <Row>
                <Col xs={12} lg={6} className="mb-3">
                    <Card border="primary" ref={refFirst} style={{ opacity: 0 }}>
                        <h5 className="card-header text-center">Free plan</h5>
                        <div className="card-body">
                            <Alert variant="primary" className="text-center">Recommended plan</Alert>
                            <ul className="list-group list-group-flush">
                                {
                                    [
                                        'Manage up to 10 subscriptions',
                                        'Visualise and track upcoming payments',
                                        'Automatically sign in with social login (Facebook, Google etc)',
                                        'Data is saved to cloud (available on any device you log in to)',
                                        'Connect and share with friends and family',
                                        'Receive timely notifications and reminders',
                                    ].map(text =>
                                        <li key={text} className="list-group-item"><GreenTick /> {text}</li>
                                    )
                                }
                                <Buttons.Login className="mt-4">Sign up for free!</Buttons.Login>
                            </ul>
                        </div>
                    </Card>
                </Col>
                <Col xs={12} lg={6} className="mb-3">
                    <Card border="light" ref={refSecond} style={{ opacity: 0 }}>
                        <h5 className="card-header text-center">Paid plan</h5>
                        <div className="card-body">
                            <Alert variant="light" className="text-center">For those with a LOT of subscriptions</Alert>
                            <ul className="list-group list-group-flush">
                                {
                                    [
                                        'Manage up to 100 subscriptions',
                                        'Visualise and track upcoming payments',
                                        'Automatically sign in with social login (Facebook, Google etc)',
                                        'Data is saved to cloud (available on any device you log in to)',
                                        'Connect and share with friends and family',
                                        'Receive timely notifications and reminders',
                                        'No advertisements'
                                    ].map(text =>
                                        <li key={text} className="list-group-item"><GreenTick /> {text}</li>
                                    )
                                }
                                <Buttons.Login className="mt-4">Sign up from $5 / year</Buttons.Login>
                            </ul>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Jumbotron>
    </PublicLayout>
}

export default IndexPage
